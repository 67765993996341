<template>
  <div class="diadin shop">
    <div class="shop-head">
      <div class="shop-head-img">
        <img style="width: 200px;height: 200px;border-radius: 10px;" :src="(forme.cover || require('../../assets/imgs/empty.png')) | image(200,200) " alt="">
      </div>
      <div class="shop-head-content font-12">
        <div>
          <div class="shop-head-content-title">
            <div class="shop-head-content-title-name">
              {{forme.title}}
              <span @click="copyTex(forme.title)" style="line-height: 31px;border:1px solid #33333365; width: 103px;height: 31px;background-color: #43434313;color: #333333;margin-right: 20px;border-radius: 5px;cursor: pointer;"> <i class="el-icon-document-copy"></i> 复制</span>
            </div>
            <div class="shop-head-content-title-type" v-if="forme.categoryList && forme.categoryList.length > 0">
              <div class="shop-head-content-title-type-tag">
                <div v-for="item in forme.categoryList">
                  <el-tag style="margin-top:8px;margin-right:8px">{{item}}</el-tag>
                </div>
              </div>

            </div>
          </div>
          <div class="font-14 shop-head-content-btn">
            <div @click="tzym(forme.shopLink)" style="width: 103px;height: 31px;background-color: #a4b9f42f;color: #4975E9;border-radius: 5px;cursor: pointer;"><i class="el-icon-link"></i>店铺链接</div>
            <el-divider direction="vertical" class="shop-head-content-btn-divider"></el-divider>
            <div v-if="this.user && this.user.id" @click="toCollectShop(forme.isCollect ? 1 : 0)" style="width: 103px;height: 31px;background-color:#008000;color: #fff;border-radius: 5px;cursor: pointer;">{{ !forme.isCollect ? '收藏' : '取消收藏'}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="font-14 shop-info">
      <el-row>
        <el-col :span="6">
          <div class="shop-info-title">开店时间</div>
          <div class="shop-info-desc">{{forme.shopOnlineTime || '/'}}</div>
        </el-col>
        <el-col :span="6">
          <div class="shop-info-title">商品数</div>
          <div class="shop-info-desc">{{(forme.items || 0) | formatMoney}}</div>
        </el-col>
        <el-col :span="6">
          <div class="shop-info-title">总销量</div>
          <div class="shop-info-desc">{{(forme.shopSold || 0) | formatMoney}}</div>
        </el-col>
        <el-col :span="6">
          <div class="shop-info-title">粉丝数</div>
          <div class="shop-info-desc">{{forme.follower || 0 | formatMoney}}</div>
        </el-col>
        <el-col :span="6">
          <div class="shop-info-title">总销售额</div>
          <div class="shop-info-desc">{{forme.shopSoldAmount || 0 | formatMoney}}</div>
        </el-col>
        <el-col :span="6">
          <div class="shop-info-title">评分</div>
          <div class="shop-info-desc">{{forme.score || 0}}</div>
        </el-col>
        <el-col :span="6">
          <div class="shop-info-title">评论数</div>
          <div class="shop-info-desc">{{forme.comments || 0 | formatMoney}}</div>
        </el-col>
        <el-col :span="6">
          <div class="shop-info-title">平均客单价（$）</div>
          <div class="shop-info-desc">{{forme.averagePrice || 0 | formatMoney}}</div>
        </el-col>
      </el-row>

    </div>

    <!--  
    <div class="shop-charts">
      <div class="shop-charts-date">
        <div>
          <span class="shop-charts-date-mark"></span>
          <span class="font-18 shop-charts-date-title">数据详情</span>
        </div>
      </div>
      <div>
        <qx style="width: 100%;height: 387px;overflow: hidden;" ref="qx" :chartName="'qx1'" :dataList='dataList'></qx>
      </div>
    </div>-->
    <!--  -->
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column prop="name" label="商品名称" width="300" align="center">
        <template slot-scope="scope">
          <div style="display: flex;text-align: left;">
            <div style="width: 77px;height:77px;margin-right: 21px;">
              <img style="width: 77px;height:77px;border-radius: 10px;" :src="(scope.row.imageUrl ? scope.row.imageUrl : require('../../assets/imgs/empty.png')) | image(77,77) " alt="">
            </div>
            <div style="width:600px;">
              <el-popover placement="top-start" width="350" trigger="hover" :content="scope.row.name">

                <!-- <span style="background-color: #e5f2fd;padding: 2px 5px;margin-right: 5px;">{{ scope.row.category }}</span> -->
                <span slot="reference" @click="details()" class="font-10" style="line-height: 15px; color:#333;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;">{{ scope.row.name }}</span>

              </el-popover>

              <div style="margin-top: 6px;">

                <span @click="spxq(scope.row.id)" class="font-10" style=" border: 1px solid #4974e96c;border-radius: 5px; background-color: #4974e91f;padding: 2px 9px;margin-right: 5px;color:#4975E9;cursor: pointer;"><i class="el-icon-link"></i>商品详情</span>

              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="secondCategory" label="品类" width="240" align="center">
        <template slot-scope="scope">
          <div style="padding: 7px 0;display: flex;justify-content:center;align-items:center">
            <el-tooltip class="item" effect="dark" :content="scope.row.secondCategory.join('>')" placement="top">
              <span class="font-10" style="overflow: hidden;text-overflow: ellipsis; white-space: nowrap;box-sizing: border-box; line-height: 17px; cursor: pointer; background-color: #4974e91f;padding: 4px 6px;color:#4975E9;border-radius: 5px;border: 1px solid #4975E9;">{{ scope.row.secondCategory[scope.row.secondCategory.length-1] }}</span>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="goodsTrends" label="销量趋势" width="180">
        <template slot-scope="scope">
          <div>
            <tendency :list="scope.row.goodsTrends" :id="scope.row.id"></tendency>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="price" label="价格" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.currencySymbol}}{{ scope.row.price | formatMoney }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="score" label="商品评分" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.score | formatMoney }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="sales" label="销售量" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.sales | formatMoney }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="onlineTime" label="市场年龄" align="center">
        <template slot-scope="scope">
          <div style="width: 100%; text-align: center">
            <div>{{ scope.row.onlineTime }}</div>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { Base64 } from 'js-base64';
import { connect } from 'echarts';
import { agetBasicInfo, bgetSaleAmtAndSumTrend, agetProductSaleTopList, getStatisticDate, collectShop, getUserInfo } from "../../api/api";
import tendency from "../../components/tendency";
import qx from "../../components/qx";

export default {
  components: {
    tendency, qx
  },
  data() {
    return {
      curPage: "1",
      pageSize: 10,
      totale: "",
      data1: "",
      radio2: "",
      forme: {},
      tableData: null,
      sjsj: '',
      pllist: [
      ],
      //图表加载效果
      loading: false,
      //默认选中日期
      date_type: 2,
      //销售量数据
      xl_data: {
        num: 0,
        hb: 0,
      },
      user: {},
      dataList: [],
    };
  },
  created() {
    this.autoLogin();
  },
  methods: {
    async autoLogin() {
      const { data: res } = await getUserInfo();
      setTimeout(() => {
        if(res.code == '0000') {
          const { data } = res;
          if(data) {
            const { user, userBenefits } = data;
            if(user) {
              console.log('confirmLoginByMobile', res, data);
              this.user = user;
              let is_vip = false;
              if(userBenefits && userBenefits.length > 0) {
                userBenefits.map(v => {
                  if(v && v.productCode && v.productCode == 1002) {
                    is_vip = v.isValid == 0 ? false : true;
                  }
                })
              }
              this.$cookie.set('is_vip', is_vip);
              this.$nextTick(() => {
                this.agetBasicInfo();
                this.bgetSaleAmtAndSumTrend();
              })
            } else {
              this.toLogin();
            }
          } else {
            this.toLogin();
          }
        } else {
          this.toLogin();
        }
      }, 1000);
    },
    toLogin() {
      let jumpUrl = process.env.VUE_APP_LOGIN_URL;
      let redirectUrl = process.env.VUE_APP_MANAGE_URL + this.$route.fullPath;
      let az = Base64.encode(redirectUrl);
      window.location.href = jumpUrl + '?redirectUrl=' + az;
    },
    async toCollectShop(data) {
      let param = {};
      param['type'] = data;
      param['userId'] = this.user && this.user.id ? this.user.id : 0;
      param['shopIds'] = [this.$route.query.id];
      const { data: res } = await collectShop(param);
      console.log('collectShop', res.data);
      this.agetBasicInfo();
    },
    spxq(e) {
      const routeData = this.$router.resolve({ path: '/productid', query: { id: e } }).href;
      console.log('routeData', routeData)
      window.open(routeData, '_blank');
    },
    //更改销售量数据展示
    changeDate(data) {
      //   this.date_type = data;
      //   this.bgetSaleAmtAndSumTrend();
      //   if(this.date_type == 1) {
      //     this.xl_data = {
      //       num: this.forme.weekSalesAmount,
      //       hb: this.forme.weekSalesAmountIncrease,
      //     }
      //   } else {
      //     this.xl_data = {
      //       num: this.forme.monthSalesAmount,
      //       hb: this.forme.monthSalesAmountIncrease,
      //     }
      //   }
      //   console.log('lx_data', this.xl_data);
    },
    //跳转链接
    tzym(e) {
      window.open(e, "_blank");
    },
    //获取日期范围
    async getStatisticDate(e) {
      let param = {};
      // param["page"] = this.curPage;
      param["dateType"] = e;
      param["page"] = 1;
      param["pagesize"] = 25;
      param["field"] = '';
      param["sort"] = '';

      param["firstCategory"] = "";

      const { data: res } = await getStatisticDate(param);
      let a = res.data

      this.sjsj = a

    },
    //复制文本
    copyTex(e) {
      let inputDom = document.createElement('textarea') // js创建一个文本框
      document.body.appendChild(inputDom) //将文本框暂时创建到实例里面
      inputDom.value = e //将需要复制的内容赋值到创建的文本框中
      inputDom.select() //选中文本框中的内容
      inputDom.focus()
      document.execCommand('copy') //执行复制操作
      document.body.removeChild(inputDom) //最后移出
      this.$message.success('复制成功')

    },
    //获取店铺详情
    async agetBasicInfo() {
      let param = {};


      param["dateType"] = this.date_type;
      param["id"] = this.$route.query.id
      param["shopId"] = '';
      param['userId'] = this.user && this.user.id ? this.user.id : 0;

      const { data: res } = await agetBasicInfo(param);

      this.forme = res.data;
      this.changeDate(this.date_type);
      this.$nextTick(() => {
        this.agetProductSaleTopList();
      })
    },
    async bgetSaleAmtAndSumTrend() {
      this.loading = true;
      let param = {};
      param["dateType"] = this.date_type;
      param["id"] = this.$route.query.id
      param["shopId"] = this.forme.shopId;
      const { data: res } = await bgetSaleAmtAndSumTrend(param);
      this.$refs.qx.list = res.data
      this.dataList = res.data;
      this.loading = false;
      //   if(this.date_type == 1) {
      //     this.pllist = [
      //       {
      //         title: '销售量指数',
      //         num: this.forme.weekSold,
      //         hb: this.forme.weekSoldIncrease
      //       },
      //       {
      //         title: '平均客单价',
      //         num: this.forme.weekAvgPrice,
      //         hb: this.forme.weekAvgPriceIncrease
      //       },
      //     ]
      //   } else if(this.date_type == 2) {
      //     this.pllist = [
      //       {
      //         title: '销售量指数',
      //         num: this.forme.monthSold,
      //         hb: this.forme.monthSoldIncrease
      //       },
      //       {
      //         title: '平均客单价',
      //         num: this.forme.monthAvgPrice,
      //         hb: this.forme.monthvgPriceIncrease
      //       },
      //     ]
      //   }

      //   this.agetProductSaleTopList(e)
      //   this.getStatisticDate(2)
      // this.forme = res.data;

    },
    async agetProductSaleTopList() {
      let param = {};


      param["dateType"] = this.date_type
      param["id"] = this.$route.query.id
      console.log('agetProductSaleTopList', this.forme)
      param["shopId"] = this.forme.shopId;


      const { data: res } = await agetProductSaleTopList(param);
      let aa = []
      res.data.records.forEach(z => {
        z.secondCategory = z.secondCategory.split(',')
        aa.push(z)
      })
      this.tableData = aa

      console.log('tableData', this.tableData)
      // this.forme = res.data;

    }
  },
  watch: {
    $route: {
      handler: function(route) {
        const query = route.query;
        // this.date_type = query.dateType && query.dateType == 2 ? 2 : 1;
      },
      immediate: true,
    },
  },

};
  </script>
      
<style lang="less" scoped>
.diadin {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 12px;
}
.shop {
  width: calc(100% - 700px);
  min-width: 1000px;
  &-head {
    display: flex;
    padding: 20px;
    align-items: flex-start;
    &-img {
      width: 200px;
      height: 200px;
      margin-right: 72px;
      border-radius: 10px;
    }
    &-content {
      width: 100%;
      height: 200px;
      display: flex;
      align-items: center;
      &-title {
        color: #333;
        margin-bottom: 14px;
        &-name {
          color: #333;
          margin-bottom: 14px;
          &-en {
            color: #999;
          }
        }
        &-type {
          width: 100%;
          &-tag {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            max-height: 120px;
            overflow: hidden;
          }
        }
      }
      &-btn {
        display: flex;
        text-align: center;
        line-height: 31px;
        align-items: center;
        &-divider {
          height: 20px !important;
          margin: 0 24px !important;
        }
      }
    }
  }
  &-charts {
    background-color: #fff;
    margin-top: 20px;
    padding: 17px;
    &-date {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      &-mark {
        display: inline-block;
        width: 4px;
        background-color: #4975e9;
        height: 15px;
        margin-right: 5px;
      }
      &-title {
        font-weight: bold;
        line-height: 15px;
      }
      &-choose {
        cursor: pointer;
        &-select {
          color: #409eff;
        }
      }
    }
  }
  &-info {
    width: 100%;
    padding: 20px;
    color: #333333;
    line-height: 31px;
    &-title {
      background: #f2f9fc;
      padding: 10px 20px;
      border: 1px solid #e4eef6;
      word-break: break-all;
      word-wrap: break-word;
    }
    &-desc {
      background: #fff;
      padding: 10px 20px;
      border: 1px solid #e4eef6;
      word-break: break-all;
      word-wrap: break-word;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &-admin {
    padding: 17px;
  }
}
.date_select {
  color: #409eff;
}
.display {
  display: flex;

  padding: 20px;
  align-items: center;
}
.border {
  border-bottom: 1px solid #eeeeee;
}
.minisize {
  font-style: 14px;
  span {
    margin: 0 8px;
    cursor: pointer;
  }
  .stotusbox {
    color: #d4d4d4;
    // font-size: 15px;
  }
}
/deep/.el-select {
  width: 100%;
}
.actoin {
  color: #409eff !important;
}
/deep/.el-form-item__content {
  width: 82%;
}
/deep/.el-input-group__append {
  width: 40%;
}
/deep/.el-input__inner {
  background-color: #f5f7fa !important;
  height: 35px !important;
  width: 100%;
}
/deep/.el-input__icon {
  line-height: 35px !important;
}
.titlable {
  width: 130px;
  min-width: 100px;
  text-align: right;
  padding-right: 10px;
}
</style>